import React from "react";
import Layout from "../components/layout";
import { Container, Row, Col } from "react-bootstrap";
import Seo from "../components/seo";
//Images
import { StaticImage } from "gatsby-plugin-image";

const Entrepreneurs = () => {
    return (
        <Layout>
            <Seo
                title="Contractors - BIKESAFE"
                description="All Bike Safe products are made with top-quality materials and expert craftsmanship. We use continuous welds and state of the art finishing processes. A hot-dipped galvanized (to BS 1461:1999) or polyester powder coated finish is standard for all racks. All of our products are made here in the UK by skilled machinists and craftsmen."
            />
            <Container>
                <Row>
                    <Col sm="12">
                        <h1 className="mt-5 mb-5 text-center">
                        Contractors
                        </h1>
                    </Col>
                </Row>
                <Row>
                    <Col lg="6" sm="12">
                        <h5 className="float-lg-left subheading-product uppercase">Materials and Craftsmanship:</h5>
                    </Col>
                    <Col lg="6" sm="12">
                        <p className="flow-text">
                        All Bike Safe products are made with top-quality materials and expert craftsmanship. We use continuous welds and state of the art finishing processes. A hot-dipped galvanized (to BS 1461:1999) or polyester powder coated finish is standard for all racks. All of our products are made here in the UK by skilled machinists and craftsmen.
                        </p>
                    </Col>
                </Row>
                <Row>
                    <Col sm="12" className="mt-5 mb-5 text-center">
                        <StaticImage placeholder="blurred"  src="../images/entrepreneurs.jpg" className="shadow fluid rounded" />
                        <em className="flow-text">"Your lead time was short and dealing with you was hassle free"
                            Lee Irvine, Irvine Utilities, London</em>
                    </Col>
                </Row>
                <Row>
                    <Col sm="12">
                        <h3>Bike Safe product features:</h3>
                        <ul className="mt-3">
                            <li className="border p-2 shade-on-hover">Maintenance Free: Our products are heavy duty and built to last</li>
                            <li className="border p-2 shade-on-hover">Easy installation for any surface</li>
                            <li className="border p-2 shade-on-hover">Aesthetics: Attractive bike parking in front of buildings enhances architecture and gets well-used</li>
                            <li className="border p-2 shade-on-hover">Efficient and orderly bicycle storage</li>
                        </ul>
                    </Col>
                </Row>
            </Container>
        </Layout>
    )
}

export default Entrepreneurs;